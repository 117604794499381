<script setup lang="ts">
const { backgroundColor = 'transparent', padding = 'theme', breakOut = false } = defineProps<{
  /**
   * Break out the background with a vertical padding?
   * NOTE/TODO: this does NOT break out full width. We need to add a new prop for that.
   * Are we still using this? Roos' redesign removed it.
   */
  // breakoutBackground?: boolean
  /**
   * The background color of the container.
   */
  backgroundColor?: 'white' | 'primary' | 'secondary' | 'muted' | 'transparent'
  /**
   * Apply the theme padding for content blocks or none?
   * Whith "theme" padding, we mean: use the padding that we have configured to be the default for content blocks.
   * However... the 'theme' padding is basically "inner" padding. Maybe we should differentiate between them.
   */
  padding?: 'none' | 'default' | 'theme'
  /**
   * Break out this component from the grid?
   * If false (the default), the container will be full width and have a max-width.
   */
  breakOut?: boolean
}>()

// For now we asume that primar and secondary are dark colors, and white and muted are light colors.
const textColor = computed(() => {
  switch (backgroundColor) {
    case 'white':
      return 'text-black'
    case 'muted':
      return 'text-black'
    case 'transparent':
      return 'text-black'
    default:
      return 'text-white'
  }
})

const contentBlockPadding = inject<string>('contentBlockPadding', 'p-8 md:py-10')

const { cardRadiusClass } = useThemeClasses()

const layoutClasses = {
  // On mobile, we want to have mx-4 by default (spacing on the sides)
  'max-w-7xl mx-auto': !breakOut,
  // AARGH... we need to fix this.
  'px-4 lg:px-0': padding == 'default',
  [contentBlockPadding]: padding == 'theme',
}

// This is a hack to add padding to the container on mobile, but not on desktop.
// For most components, we want this. That's why I made it the default.
// const mobileOnlyPadding = 'mx-4 md:mx-auto'

</script>

<template>
  <div
    :class="{
      [textColor]: true,
      [cardRadiusClass]: true,
      [`bg-${backgroundColor}${backgroundColor === 'white' ? '' : '-500'}`]: true,
      'dark:bg-muted-900': backgroundColor !== 'transparent',
      ...layoutClasses,
    }"
    class="relative dark:text-white"
  >
    <slot />
  </div>
</template>
